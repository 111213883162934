import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { renderCheckboxButtonGroup } from '../../util/formik-custom-fields';
import CheckboxButton from '../../components/CheckboxButton/CheckboxButton';
import { CheckboxButtonGroupProps } from '../../components/CheckboxButtonGroup/CheckboxButtonGroup';
import { useNotiPreference } from '../../util/APIDjango';

interface NotificationPreferenceFieldProps {
  name: string;
  label: string;
}

type NotificationPreference = {
  id: number;
  label: string;
  value: string;
};

export const pushNotificationValue = 'push';

const NotificationPreferenceField: FC<NotificationPreferenceFieldProps> = ({ name, label }) => {
  const [notiPreferenceData, setNotiPreferenceData] = useNotiPreference();
  const [preference, setPreference] = useState(notiPreferenceData.notificationMethod);

  useEffect(() => {
    setPreference(notiPreferenceData.notificationMethod);
  }, [notiPreferenceData]);

  const { t } = useTranslation();
  const options: NotificationPreference[] = [
    {
      id: 1,
      label: t('notificationOptionsEmail.label'),
      value: 'email',
    },
    {
      id: 2,
      label: t('notificationOptionsPush.label'),
      value: pushNotificationValue,
    },
    {
      id: 3,
      label: t('notificationOptionsOptout.label'),
      value: 'optout',
    },
  ];

  const handleRenderItems: CheckboxButtonGroupProps['renderItems'] = (
    _selectedValue,
    handleSelect,
    getSelectedState,
  ) =>
    options.map((option: NotificationPreference) => (
      <CheckboxButton
        key={option.id}
        id={`push-${option.id}`}
        value={option.value}
        label={option.label}
        checked={option.value === preference ? true : false}
        onChange={() => {
          handleSelect(option.value);
          setPreference(option.value);
        }}
      />
    ));

  return (
    <Field
      name={name}
      label={label}
      component={renderCheckboxButtonGroup}
      renderItems={handleRenderItems}
    />
  );
};

export default NotificationPreferenceField;
