import React, { createContext, useState, useEffect } from 'react';
import { SavePassword } from 'capacitor-ios-autofill-save-password';
import { Device } from '@capacitor/device';
import api from '../util/api';
import { useAuth, useProfile } from '../util/APIDjango';
import { isNative } from '../util/isNative';
import { pushNotificationValue } from '../forms/fields/NotificationPreferenceField';
import { PushNotifications } from '@capacitor/push-notifications';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useAuth();

  const [profileData, setProfileData] = useProfile();
  const { uid, user, authenticated, error, redirectToReferrer } = authState;
  useEffect(() => {
    if (authenticated) setAuthState({ ...authState, redirectToReferrer: true });
  }, [authenticated]);

  const login = () => setAuthState({ ...authState, authenticating: true, error: null });

  const loginFulfill = uidReturned =>
    setAuthState({
      ...authState,
      uid: uidReturned,
      authenticated: true,
      authenticating: false,
      error: null,
    });

  const loginReject = errorMessage =>
    setAuthState({ ...authState, authenticating: false, error: errorMessage });

  const logout = () => setAuthState({ ...authState, unauthenticating: true, error: null });

  const logoutFulfill = () =>
    setAuthState({
      ...authState,
      uid: null,
      authenticated: false,
      unauthenticating: false,
      error: null,
    });

  const logoutReject = errorMessage =>
    setAuthState({ ...authState, unauthenticating: false, error: errorMessage });

  const updateFCMToken = () => {
    // console.log("fcm token called");
    const fcmToken = localStorage.getItem('FCMToken');
    if (fcmToken) {
      api.updateFirebaseToken(fcmToken);
      // console.log("fcm token updated");
    }
    // if (!fcmToken) {
    //   console.log("no fcm token available");
    // }
  };

  const handleSignIn = (values, { setSubmitting }) => {
    setSubmitting(true);
    login();
    api
      .signIn(values?.email, values?.password)
      .then(uidReturned => {
        setSubmitting(false);
        loginFulfill(uidReturned);

        updateFCMToken();

        void api
          .profileGet()
          .then(data => {
            setProfileData({
              ...profileData,
              ...data,
              profile: data,
              isFetching: false,
              isFetched: true,
              error: null,
            });
            if (isNative && data.notificationMethod === pushNotificationValue) {
              // iOS will prompt user and return if they granted permission or not
              // Android will just grant without prompting
              PushNotifications.requestPermissions()
                .then(result => {
                  if (result.receive === 'granted') {
                    // Register with Apple / Google to receive push via APNS/FCM
                    void PushNotifications.register();
                  } else {
                    // TODO handle error
                  }
                })
                .catch(() => {
                  // TODO handle error
                });
            }
          })
          .catch(error => {
            setProfileData({ ...profileData, error: error, isFetching: false });
          });

        return Device.getInfo().then(
          ({ platform }) =>
            platform === 'ios' &&
            SavePassword.promptDialog({
              username: values?.email,
              password: values?.password,
            }),
        );
      })
      .catch(err => {
        setSubmitting(false);
        loginReject(err.message);
      });
  };

  const handleSignOut = () => {
    logout();
    api
      .signOut()
      .then(() => {
        logoutFulfill();
      })
      .catch(err => {
        logoutReject(err);
      });
  };

  const handleForgotPassword = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    api
      .forgotPassword(values)
      .then(() => {
        setStatus({ complete: true });
        setSubmitting(false);
      })
      .catch(() => {
        setStatus({ complete: true });
        setSubmitting(false);
      });
  };

  const handleResetPassword = (values, { setSubmitting, setStatus }) => {
    setSubmitting(true);
    api
      .resetPassword(values)
      .then(() => {
        setStatus({ complete: true });
        setSubmitting(false);
      })
      .catch(err => {
        let formError = err.response.detail;
        const responseError = err.response[Object.keys(err.response)[0]];
        if (responseError && Array.isArray(responseError)) {
          [formError] = responseError;
        }
        setStatus({ complete: false, formError });
        setSubmitting(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        error,
        user,
        uid,
        redirectToReferrer,
        handleSignIn,
        handleSignOut,
        handleForgotPassword,
        handleResetPassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
